<!-- MultiOrder 진입 시 Tax(개인/사업자) 정보가 없을 경우 주문에 Tax를 설정하는 모달 폼 -->
<template>
  <div class="bg-white-gray px-5">
    <b-modal id="bv-modal-how-much-create-detail"
      fade
      hide-footer 
      no-close-on-backdrop
      header-class="how-much-header pb-1"
      dialog-class="how-much-dialog"
      content-class="how-much-content"
      body-class="how-much-body"
      modal-class="how-much-modal"
    >
      <template #modal-header="{ close }">
        <div class="top-title df-center">
<!--          <h4 class="mr-1">하우머치</h4>-->
<!--          <span>(온라인 견적요청)</span>-->
        </div>
        <img src="@/assets/svg/icon-cross.svg" alt="닫기" class="c-pointer" style="width: 15px; height: 15px;" @click="close()">
      </template>

      <div>
        <div>
          <div>
            <div class="mt-1">
              <div class="df-center mr-5">
                <p class="mr-1">제목</p>
                <p class="fc-r">(필수)</p>
              </div>
              <b-input v-model="detailInfo.title" placeholder="견적서 제목을 입력하세요"></b-input>
            </div>

            <div class="mt-3">
              <div class="df-center mr-5">
                <p class="mr-1">요청사항</p>
              </div>
              <b-textarea v-model="quoteContent"
                          placeholder="※ 견적 요청사항을 입력하세요"
                          rows="3"
                          max-rows="8">
              </b-textarea>
            </div>

            <div class="mt-3">
              <div class="df-center mr-5 mb-1">
                <p class="mr-1">브랜드</p>
              </div>
              <div class="df-center">
                <div class="mr-3">
                  <b-dropdown text="창호" toggle-class="dropdown-toggle-custom">
                    <b-dropdown-item v-for="(item, index) in brandTypes" :key="index" @click="selectBrandType(item.value)">{{ item.text }}</b-dropdown-item>
                  </b-dropdown>
                </div>

                <div class="df-center" v-if=" brandType === 'windows' ">
                  <div class="df-center mr-4" v-for="(item, index) in windowsBrands" :key="index">
                    <div class="n_bg_checkBox_gray_selected mr-1 c-pointer" :class="{'u_bg_checkBox_red_selected': brandName === item}" @click="toggleBrandType(index, item)" />
                    <p :class="{'fb': brandName === item}">{{ item }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-3">
              <div class="df-center mr-5 mb-1">
                <p class="mr-1">견적 참고파일</p>
<!--                <p class="fc-g">(도면, 이미지 등..)</p>-->
              </div>
              <b-form-file
                  v-model="file1"
                  placeholder="참고 파일을 업로드해주세요 (도면, 이미지, 압축파일 등..)"
                  drop-placeholder="파일을 이곳에 드래그해주세요"
                  accept="image/*, .dwg, .zip, .7z"
              ></b-form-file>
            </div>
          </div>

          <div class="mt-4" style="border-bottom: solid 1px #b3b3b3;">
            <div class="df-center mb-1" v-b-toggle.contact-info @click="toggleContactInfo()">
              <b-icon class="mr-1" icon="caret-up-fill" v-if="isContactInfo" />
              <b-icon class="mr-1" icon="caret-down-fill" v-if="!isContactInfo" />
              담당자 정보 <b-badge v-if="contactInfoVerify()" class="ml-1" variant="danger">입력해주세요!</b-badge>
            </div>
            <div>
              <b-collapse id="contact-info">
                <b-card>
                  <div>
                    <b-input-group>
                      <b-input-group-prepend is-text>회사명</b-input-group-prepend>
                      <b-input v-model="contactInfo.compName" placeholder="정보를 입력해주세요." />
                    </b-input-group>

                    <b-input-group class="mt-2">
                      <b-input-group-prepend is-text>이름</b-input-group-prepend>
                      <b-input v-model="contactInfo.name" placeholder="정보를 입력해주세요." />
                    </b-input-group>

                    <b-input-group class="mt-2">
                      <b-input-group-prepend is-text>이메일</b-input-group-prepend>
                      <b-input v-model="contactInfo.email" placeholder="정보를 입력해주세요." />
                    </b-input-group>

                    <b-input-group class="mt-2">
                      <b-input-group-prepend is-text>연락처</b-input-group-prepend>
                      <b-input v-model="contactInfo.tel" placeholder="정보를 입력해주세요." />
                    </b-input-group>
                  </div>

                  <small>
                    정보가 자동으로 나오지 않나요? 계정 정보를 <a href="#/service/member-password-verify" target="_blank">업데이트</a>해보세요!
                  </small>
                </b-card>
              </b-collapse>
            </div>
          </div>

          <div class="df-center mt-4 mr-1" style="justify-content: space-between;">
            <div class="df-center">
              <div class="df-center mr-5">
                <p class="mr-1">결제방법</p>
                <p class="fc-r">(필수)</p>
              </div>

              <div class="df-center">
                <div class="df-center mr-5">
                  <div class="n_bg_checkBox_gray_selected mr-1 c-pointer" :class="{'u_bg_checkBox_red_selected': payType === 'CASH'}" @click="toggleCheckBox('CASH')" />
                  <p :class="{'fb': payType === 'CASH'}">현금</p>
                </div>

                <div class="df-center mr-5">
                  <div class="n_bg_checkBox_gray_selected mr-1 c-pointer" :class="{'u_bg_checkBox_red_selected': payType === 'CARD'}" @click="toggleCheckBox('CARD')" />
                  <p :class="{'fb': payType === 'CARD'}">카드</p>
                </div>
              </div>
            </div>

            <div class="df-center df-end">
              <div class="n_bg_checkBox_gray_selected mr-1 c-pointer" :class="{'u_bg_checkBox_red_selected': isSecret}" @click="isSecret = !isSecret" />
              <p>비공개</p>
            </div>
          </div>
        </div>


        <div class="df-center mt-1 mr-1" style="justify-content: flex-end;">
          <div class="df-center df-end">
            <div class="n_bg_checkBox_gray_selected mr-1 c-pointer" :class="{'u_bg_checkBox_red_selected': isProvideConsent}" @click="isProvideConsent = !isProvideConsent" />
            <p>제3자 정보제공 동의</p>
          </div>
        </div>

        <div class="mt-5" style="text-align: center;">
          <button class="btn-howcost-red fb" style="width: 50%;" @click="quoteCreateSubmit">견적 신청하기</button>
        </div>
      </div>

    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
import {apiCall, apiMultiPart, warpSiteDirectMove} from '@/common/utils';
import { WindowsBrands } from '@/common/specType';
import '@/assets/scss/checkBox.scss'
import { HowmuchCategoryOpts } from '@/common/specType';

export default {
  name: 'HowMuchCreateDetail',
  props: {
    detailInfo: {
      type: Object,
    }
  },
  data() {
    return {
      today: moment().add(9, 'hours'),
      payType: 'CASH',
      quoteContent: '',
      file1: null,
      isSecret: false,
      isProvideConsent: true,

      isContactInfo: false,
      contactInfo: {
        compName: '',
        name: '',
        email: '',
        tel: '',
      },

      brandType: 'windows',
      brandTypes: HowmuchCategoryOpts,

      brandName: '',
      windowsBrands: WindowsBrands,

    }
  },
  computed: {
    user() { return this.$store.state.userStore.user; },
  },
  async created() {
    // console.log('user ======> ', this.user);
    if (this.user) {
      this.contactInfo.name = this.user.name;
      this.contactInfo.email = this.user.email;
      this.contactInfo.tel = this.user.mobile;

      if (this.user.business && this.user.business.compName) {
        this.contactInfo.compName = this.user.business.compName;
      }
      // console.log('this.contactInfo ======> ', this.contactInfo);
    }
  },
  methods: {
    toggleCheckBox(type) { this.payType = type; },
    selectBrandType(type) { this.brandType = type; },
    toggleBrandType(index, item) { this.brandName = item; },
    toggleContactInfo() { return this.isContactInfo = !this.isContactInfo },

    contactInfoVerify() { return !this.contactInfo.compName || !this.contactInfo.name || !this.contactInfo.email || !this.contactInfo.tel; },

    async quoteCreateSubmit() {
      if (!this.payType) return alert("결제방법을 선택해주세요.");
      if (!this.isProvideConsent) return alert("제 3자 정보제동 동의를 하지 않으면 이용하실 수 없습니다.");
      if (!this.detailInfo.title) return alert("견적 제목을 입력해주세요.");
      if (!this.brandName) return alert("브랜드를 선택해주세요.");
      if (this.contactInfoVerify()) return alert("담당자 정보를 확인해주세요.");
      if (this.file1 && !this.fileFormatVerify(this.file1)) return alert("해당 첨부파일은 지원하지 않는 확장자입니다.");

      const params = {
        payType: this.payType,
        title: this.detailInfo.title,
        content: this.quoteContent,
        projectName: this.detailInfo.projectName,
        part: this.detailInfo.part,
        startDate: this.detailInfo.startDate,
        endDate: this.detailInfo.endDate,
        buildArea: this.detailInfo.buildArea,
        buildArea2: this.detailInfo.buildArea2,
        grossArea: this.detailInfo.grossArea,
        grossArea2: this.detailInfo.grossArea2,
        resources: this.detailInfo.resources,
        isSecret: this.isSecret,
        brandType: this.brandType,
        brandName: this.brandName,
        contactInfo: this.contactInfo,
      }

      try {
        const r = await apiCall('POST', `/api/how-much/create`, params);
        // console.log(r);

        const {seq} = r.result;
        if (this.file1) {
          let formData = new FormData();
          formData.append("files", this.file1);
          const part = await apiMultiPart(`/api/how-much/file-upload/${seq}`, formData);
          // console.log(part);
        }

        alert("신청하신 자재 견적이 게시되었습니다.");
        await warpSiteDirectMove(`service/howmuch/detail/${seq}`);
      } catch (e) {
        console.error(e);
      }
    },

    fileFormatVerify(file) {
      const verifiedFormats = ['jpg', 'png', 'jpeg', 'gif', 'dwg', 'zip', '7z'];

      const fileName = file.name;
      const lastDotIndex = fileName.toString().lastIndexOf('.');
      const fileFormat = fileName.toString().slice(lastDotIndex+1);
      // console.log(fileFormat);

      for (const vf of verifiedFormats) {
        if (vf === fileFormat) {
          return true;
        }
      }
      return false;
    }

  },
}
</script>

<style>
p {
  margin: 0;
}
.content-card {
  background-color: rgba(255, 255, 255) !important;
  border: solid 1px #eaeaea;
  border-radius: 4px;
  padding: 1em 2em;
}

.how-much-header {
  align-items: center;
  padding: 1rem 0 0 0 !important;
  border-bottom: none !important;
  /*border-bottom: solid 2px #e65d5d !important;*/
}
.how-much-dialog {
  margin-top: 120px !important;
  max-width: 700px;
}
.how-much-content {
  padding: 1rem 2rem !important;
  color: #000000;
}
.how-much-body {
  padding: 0.252rem 0 !important;
}
.how-much-modal {
  transition: opacity 0.3s ease-in-out;
}

.dropdown-toggle-custom {
  border-radius: 0;
  background-color: white;
}

</style>
