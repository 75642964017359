<template>
  <div id="wrapper" class="bg-w noto-sans fc-b">

    <div class="pt-3 mt-3 container">

      <div class="pt-3 df-center" style="justify-content: space-between;">
        <div class="top-title df-center">
          <h4 class="mr-1">하우머치</h4>
          <span>(온라인 견적요청)</span>
        </div>

        <div class="top-title df-center">
          <span class="fc-g">자재를 온라인을 통해 손쉽게 견적 받아보세요</span>
        </div>
      </div>
      <hr style="margin-top:0.125rem;margin-bottom: 0.525rem;display: block;width: 100%;height: 1px;background-color: #63a3ff;opacity: 0.8;">

      <div class="df-between mt-4 mb-4 fc-gg">
        <div class="">
          <h5>{{detailInfo.projectName}}</h5>
          <p><strong class="fb">공사구분: </strong>{{detailInfo.part}}</p>
          <p><strong class="fb">공사기간: </strong>{{detailInfo.startDate}} ~ {{detailInfo.endDate}}</p>
          <div class="df-center">
            <p class="mr-3"><strong class="fb">건축면적: </strong>{{detailInfo.buildArea}}m<sup>2</sup>({{detailInfo.buildArea2}}평)</p>
            <p><strong class="fb">연면적: </strong>{{detailInfo.grossArea}}m<sup>2</sup>({{detailInfo.grossArea2}}평)</p>
          </div>
        </div>

        <div v-if="user" class="">
          <h5>담당자 정보</h5>
          <p><strong class="fb">회사명: </strong></p>
          <p><strong class="fb">이　름: </strong>{{ user.name }}</p>
          <p><strong class="fb">연락처: </strong>{{ user.mobile }}</p>
          <p><strong class="fb">이메일: </strong>{{ user.email }}</p>
        </div>
      </div>

      <div class="px-3" style="margin: 1.525rem 0 1.252rem 0;">
        <b-table
            head-variant="white"
            :items="resources"
            :fields="fields"
            sort-icon-left
            responsive="sm"
            class="items-table"
        ></b-table>
      </div>

      <div class="py-4 mb-3 df-center" style="justify-content: center; color: #474747;">
        <span class="fc-g">== 이곳에 요청사항이 기입됩니다 ==</span>
      </div>

      <hr style="margin-top:0.125rem;margin-bottom: 0.525rem;display: block;width: 100%;height: 1px;background-color: #63a3ff;opacity: 0.8;">

      <div class="df-end">
        <b-button class="mt-2 btn-howcost fb" style="width: 26%;" @click="$bvModal.show('bv-modal-how-much-create-detail')">
          견적 진행하기
          <span class="ml-1">▶</span>
        </b-button>
      </div>

    </div>

    <Spinner
        :showSpinner="loading"
        :showMessage="loadingMessage"
        :infoMessage="detailMessage">
    </Spinner>

    <HowMuchCreateDetail :detailInfo="detailInfo" />
  </div>
</template>


<script>
import {
  apiCall,
  apiMultiPart,
  cloneVar,
  alertError,
  alertWarn,
  alertSuccess,
  alertConfirm,
  alertPageMove,
  warningAlertPageMove,
  getMask,
  warpSite,
} from "@/common/utils";
import HowMuchCreateDetail from "./HowMuchCreateDetail";

export default {
  name: 'HowMuchCreate',
  components: {
    HowMuchCreateDetail
  },
  props: ['userShortToken'],
  data() {
    return {
      loading: true,
      loadingMessage: '견적 생성중',
      detailMessage: '',

      detailInfo: {},
      resources: [],
      fields: [
        { key: "CODE", label: "코드", thClass: "w15" },
        { key: "NAME", label: "품명", thClass: "w35" },
        { key: "STAD", label: "규격", thClass: "w30" },
        { key: "UNIT", label: "단위", thClass: "w10" },
        { key: "QUAN", label: "수량", thClass: "w10" },
        // { key: "REMARK", label: "비고", thClass: "w20" },
      ],
    };
  },

  computed: {
    user() { return this.$store.state.userStore.user; },
    isAuth() { return this.$store.state.userStore.isAuth; }
  },

  async created() {
    try {
      const r = await apiCall('POST', `/api/how-much/quote-token/analysis/${this.userShortToken}`);
      if (r.code === 200) {
        this.detailInfo = r.result;
        this.resources = r.result.resources;

        const rs = await this.$store.dispatch('userStore/xCostLinkLogin', {xCostToken: r.result.xCostToken});

        if (!rs.isLogin) {
          alert("Xcost 연동 로그인이 실패했습니다 재시도 해주세요.");
          window.close();
          window.location = '/';
          return;
        }
        if (!this.isAuth) {
          window.location.reload();
          return;
        }
        this.loading = false;
      }
      if (r.code === 401) {
        alert(r.message);
        window.close();
        window.location = '/';
        return;
      }
      if (r.code === 403) {
        alert(r.message);
        await this.userInfoVerify(r.result);
      }
    } catch (e) {
      console.error(e);
    }
  },

  methods: {
    async userInfoVerify(user) {
      try {
        // 기존 회원정보 수정 폼 활용
        const params = {
          id: user.userId,
          no: user.userNo,
          hostName: window.location.hostname
        }

        const r = await apiCall('post', `/api/member/update-request`, params);

        // 강제 로그아웃
        this.$store.dispatch('userStore/LOGOUT');

        window.location = r.result.link;
      } catch (e) {
        console.error(e);
      }
    },
  }
};
</script>

<style scoped>

.items-table {
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
}

</style>